.nlk-flex-container{
    display: flex ;
    direction: row ;
    justify-content: space-between ;
    flex-wrap: wrap ;
    margin: auto;
    width: 90vw !important;
}

.nlk-nav-icon {
    width: 30px;
    height: 30px;
}