.features {
    width: 100vw;
    padding: 50px;
}

.features-title {
    margin:auto;
    width: 30vw;
    text-align: center;
    font-size: 3.2vw;
    margin-bottom: 50px;
}

.features-card{
    height: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
}

.card-icon{
    font-size: 80px;
    color: rgba(13, 74, 114, 0.87);
}