.coop {
    margin-top: 50px;
    margin-bottom: 50px;
    max-height: 400px;
}

.coop > hr {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.coop-title {
    text-align: center;
    font-size: 26px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.coop-container {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.link {
    text-decoration: none;
    color: inherit;
}