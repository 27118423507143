.contactus-container {
    padding: 60px;
    max-width: 80vw;
    margin: auto !important;
    margin-top: 50px !important;
}

.contactus-text-container {
    display: flex;
    flex-direction: column;
    height: 160px;
    justify-content: space-between;
}

.bold-text {
    font-weight: bold;
    color:rgb(34,35,39);
}

.contactus-subtitle {
    margin-bottom: 30px !important;
}

.contactus-icon {
    vertical-align: middle;
    display: 'inline-flex';
    color: rgb(34,35,39);
}

@media screen and (max-width: 522px) {
    .contactus-container {
        padding: 0px;
    }
}