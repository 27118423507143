.nlk-hero {
    margin-top: 74px;
    width: 100vw;
    height: calc(100vh - 74px);
    display: flex;
    padding: 50px 15px;
    text-align: center;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    position: relative;
    background-size: cover;
    background-position: center;
}

.nlk-hero-text {
    color: rgba(255, 255, 255, 0.938);
    z-index: 7;
    max-width: 100%;
    margin:auto;
}

.nlk-hero-text > .hero-h4 {
    font-size: 40px;
    margin-bottom: 5vh;
}

.nlk-hero-text > .hero-h5 {
    font-size: 20px;
}

.nlk-layer {
    background-color: rgba(13, 74, 114, 0.87);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 100px 60px rgba(0, 0, 0, 0.4), inset 0px 30px 60px 0px rgba(0, 0, 0, 0.4), inset 0px -30px 60px 0px rgba(0, 0, 0, 0.4);
}

.slide {
    z-index: 4;
    transition: ease-in-out 2s;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.active {
    opacity: 1;
}

.inactive {
    opacity: 0;
}

.slide:nth-of-type(1){
    background-image: url('../../assets/img/arm_fancy.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
.slide:nth-of-type(2){
    background-image: url('../../assets/img/arm_stack.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.slide:nth-of-type(3){
    background-image: url('../../assets/img/arm_geometry.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.slide:nth-of-type(4){
    background-image: url('../../assets/img/arm_bars.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
    .nlk-hero {
        height: calc(100vh - 74px);
    }
    
    .nlk-hero-text > .hero-h4 {
        font-size: 4vw;
        text-align: center;
        margin-bottom: 5vh;
    }
    
    .nlk-hero-text > .hero-h5 {
        font-size: 1.8vw;
        text-align: center;
    }
}