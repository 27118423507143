.grt-hero {
    margin-top: 74px;
    margin-bottom: 50px;
    width: 100vw;
    height: 300px;
    display: flex;
}

.grt-hero-text {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 150px 15px;
    flex-direction: column;
    background-color: rgba(63,81,181, 0.1);
    align-content: flex-end;
    justify-content: flex-end;
}

.grt-hero-img {
    height: 0px;
    width: 0px;
    position: relative;
}

.grt-hero-img > img {
    height: 0px;
    width: 0px;
}

.grt-layer {
    background-color: rgba(33, 104, 151, 0.15);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 100px 60px rgba(0, 0, 0, 0.4), inset 0px 30px 60px 0px rgba(0, 0, 0, 0.4), inset 0px -30px 60px 0px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 1024px) {
    .grt-hero {
        height: 30vw;
    }

    .grt-hero-text {
        width: 50%;
    }

    .grt-hero-text > .hero-h4 {
        font-size: 2.5vw;
    }
    
    .grt-hero-text > .hero-h5 {
        font-size: 2vw;
    }

    .grt-hero-img {
        height: 100%;
        width: 50%;
    }
    
    .grt-hero-img > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}