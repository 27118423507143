.nlk-services {
    width: 100vw;
    background-color: rgba(34, 35, 39, 1);
    color: rgb(211, 211, 211);
    padding: 50px; 
}

.services-img {
    width: 35vw;
    margin: auto;
    height: 100%;
    min-height: 35vh;
    background-size: cover;
    background-position: center;
    background-position-y: top;
    background-image: url('../../assets/img/arm_bridge.jpg');
}

@media screen and (max-width: 1024px){
    .services-img { 
        width: 100%;
    }
}