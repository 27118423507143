.nlk-contact {
    margin-top: 50px;
    padding: 20px;
    padding-left: 80px;
    padding-top: 0px;
    background-color: rgba(34, 35, 39, 1);
    color: rgb(211, 211, 211);
}

.nlk-contact > .container {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (min-width: 1024px) {
    .nlk-contact > .container {
        text-align: center;
    }
    .nlk-contact {
        padding: 20px;
        padding-top: 0px;
    }
}