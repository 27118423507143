.grt-services {
    width: 100vw;
}

.grt-services  h5 {
    text-align: center;
}

.grt-services .card {
    width: 300px;
    margin: auto;
}


@media screen and (min-width: 1024px){
    .grt-services  .services-h5 {
        font-size: 2.7vw;
    }

    .grt-services .card {
        width: 400px;
        margin: auto;
    }
}
